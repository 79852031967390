export default {
    infoNum: 1,
    welfareInfo: [{
        id: 1,
        title: '弹性福利',
        appImg: require('../image/welfareDetail1.png'),
        p1: '为企业灵活搭配福利品类、量身定制福利方案，满足员工多元化需求，有利于吸引优秀人才、留住核心人才，助力企业控制福利成本，有效平衡企业管理与员工满意度。',
        p2: '企业福利采购',
        p3: '灵活兑换',
        p4: '多场景匹配',
    }, {
        id: 2,
        title: '员工激励',
        appImg: require('../image/welfareDetail2.png'),
        p1: '以员工在团队协作、创新能力、工作模式等方面的表现为评价依据，据实奖励员工，激发员工工作动力，提高员工工作积极性，有利于企业文化的快速渗透，增强员工对企业的认同感与责任感。',
        p2: '年节关怀',
        p3: '健康体检',
        p4: '及时奖酬',
    }, {
        id: 3,
        title: '客户关怀',
        appImg: require('../image/welfareDetail3.png'),
        p1: '番茄福利提供多种礼品品类，根据企业客户的需求及特性提供客户关怀解决方案，传递企业关怀，提升企业形象，省去采购、囤货、发货等繁琐的流程，最大程度为企业减负增效。',
        p2: '商务馈赠',
        p3: '商务馈赠',
        p4: '关怀礼品',
    }],
    welfareBoxList: [{
        img: require('../image/welfare-xuanpin.png'),
        p1: '严选一线品牌权益产品，保障福利品质，高效匹配多种消费场景，满足个性化需求，为HR减负提效，助力企业实现福利数字化转型。'
    }, {
        img: require('../image/welfare-fuli.png'),
        p1: '集成员工福利、员工奖励机制方案、企业礼品定制等所需的应用、数据、流程及服务，专业运营，实现99%的员工及客户满意度。'
    }, {
        img: require('../image/welfare-jishu.png'),
        p1: '线上云平台研发服务，凭借强大的亿级数据处理能力，可进行多终端连接自由管理，后台可视化统计报表，持续优化架构解决深层次问题。'
    }, {
        img: require('../image/welfare-shouhou.png'),
        p1: '专人专项服务，完善的服务监控、数据备份机制，提供7*24小时客服解决方案，让服务渗透每处细节，为企业智慧福利建设锦上添花'
    }],
    phoneList: [{
        img: require('../image/welfare/img1.png'),
        img2: require('../image/brandIcon.png'),
        t1: '年节关怀方案',
        p1: '多样化节日模板、智能化商品推荐',
        t2: '智能化福利管理工具',
        p2: '免费提供PC、移动端工具，提高福利管理效率，优化成本。'
    }, {
        img: require('../image/welfare/img2.png'),
        img2: require('../image/brandIcon2.png'),
        t1: '丰富互动工具',
        p1: '企业专属活动页面、公告管理、积分提醒',
        t2: '全场景福利覆盖',
        p2: '年节关怀、企业活动、弹性福利、员工特权。'
    }, {
        img: require('../image/welfare/img3.png'),
        img2: require('../image/brandIcon3.png'),
        t1: '精选优质商品',
        p1: '1000+知名品牌商、企业专享价格、不断丰富的权益产品',
        t2: '全方位服务',
        p2: '官方海量商品、精准线上分发、安全无忧售后。'
    }, {
        img: require('../image/welfare/img4.png'),
        img2: require('../image/brandIcon4.png'),
        t1: '售后服务集成',
        p1: '400客服7*24小时服务、企业客户专享客户经理',
        t2: '强大运营能力',
        p2: '福利选择>采买>分发>监控>反馈，数据在线实时监控。'
    }]
}
<template>
  <div class="welfare">
    <!-- 顶部导航栏 -->
    <topNav></topNav>

    <!-- 背景开始 -->
    <div class="top-bg">
      <div class="top-bg-content">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>企业弹性福利解决方案</div>
            <div>
              礼品供应丨专业策划丨系统支撑
            </div>
          </div>
        </div>
      </div>
      <div class="top-bg-content-phone">
        <div class="top-content-bannar">
          <div class="top-bannar-vis">
            <div>福利及营销解决方案提供商</div>
            <div>
              企业福利丨员工奖励丨客户回馈
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 背景结束 -->

    <!-- 中间部分开始 -->
    <div class="sectionAll">
      <!-- 部分1开始 -->
      <div class="section-1">
        <h2>深度打造更具弹性的企业福利体系</h2>
        <h4 class="vice-title">连接企业、员工、客户，助力企业降本增效</h4>
        <ul class="title-wrap">
          <li
            class="bank-title"
            v-for="(v, i) in list.welfareInfo"
            :key="i"
            @click="toggle(i)"
            :class="{ active: v.id == list.infoNum }"
          >
            <p>{{ v.title }}</p>
          </li>
        </ul>
        <ul class="content">
          <li
            class="info"
            v-for="(v, i) in list.welfareInfo"
            :key="i"
            v-show="v.id == list.infoNum"
          >
            <div class="info-detail">
              <p class="detail-introduce">
                {{ v.p1 }}
              </p>
              <p class="detail-tips">
                <span>{{ v.p2 }}</span>
                <span>{{ v.p3 }}</span>
                <span>{{ v.p4 }}</span>
              </p>
              <p class="detail-jump">
                <span>立即咨询</span>
              </p>
            </div>
            <div class="info-img">
              <img :src="v.appImg" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <!-- 部分1结束 -->

      <!-- 部分2开始 -->
      <div class="section-2">
        <img src="../assets/image/welfareBg2.png" alt="" />
      </div>
      <!-- 部分2结束 -->

      <!-- 部分3开始 -->
      <div class="section-3">
        <h2 class="super-tit">严选各品牌供应商，提升福利价值</h2>
        <h4 class="vice-title">千款礼品随心选，理想福利即刻升级</h4>
        <div class="vague">
          <div class="logoMove">
            <div class="logo1">
              <img src="../assets/image/logomove1.png" alt="" />
              <img src="../assets/image/logomove2.png" alt="" />
            </div>
            <div class="logo2">
              <img src="../assets/image/logomove2.png" alt="" />
              <img src="../assets/image/logomove3.png" alt="" />
            </div>
            <div class="logo1">
              <img src="../assets/image/logomove3.png" alt="" />
              <img src="../assets/image/logomove4.png" alt="" />
            </div>
            <div class="logo2">
              <img src="../assets/image/logomove4.png" alt="" />
              <img src="../assets/image/logomove5.png" alt="" />
            </div>
            <div class="logo1">
              <img src="../assets/image/logomove5.png" alt="" />
              <img src="../assets/image/logomove1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 部分3结束 -->

      <!-- 部分4开始 -->
      <div class="section-4">
        <h2 class="title">专注企业福利一站式服务</h2>
        <h4 class="vice-title">挖掘福利新概念，助力企业数字化转型</h4>
        <ul class="clearfix">
          <li
            class="welfare-box"
            v-for="(v, i) in list.welfareBoxList"
            :style="{
              background: 'url(' + v.img + ') center center no-repeat',
            }"
            :key="i"
          >
            <p class="box-tips">
              {{ v.p1 }}
            </p>
          </li>
        </ul>
      </div>
      <!-- 部分4结束 -->
    </div>
    <!-- 中间部分结束 -->

    <!-- 中间移动部分开始 -->
    <div class="sectionPhoneAll">
      <div class="finane">
        <h2>番茄福利</h2>
        <p class="content">
          依托强大的技术支撑，番茄券聚集衣食住行游购娱等各个领域的优质权益资源，为企业的年节关怀提供专属权益服务，让企业品牌宣传与福利宣贯，使关怀更加深入人心、更有温度。
        </p>
      </div>
      <div class="phoneAll-2">
        <h1>挖掘福利新概念，提升员工忠诚度</h1>
        <div class="two-wrap">
          <ul class="two-list">
            <li class="two-item" v-for="(v, i) in list.phoneList" :key="i">
              <img :src="v.img2" alt="" />
              <h2>{{ v.t1 }}</h2>
              <p>{{ v.p1 }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="phoneAll-3">
        <h1>我们的优势</h1>
        <div class="three-wrap">
          <ul>
            <li class="three-item" v-for="(v, i) in list.phoneList" :key="i">
              <img :src="v.img" alt="" />
              <div class="three-right">
                <h2 class="right-titile">{{ v.t2 }}</h2>
                <p class="three-text">
                  {{ v.p2 }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 中间移动部分结束 -->

    <Bottom></Bottom>
  </div>
</template>

<script>
import "../assets/less/welfare.less";
import alllist from "../assets/js/index";
import list from "../assets/js/welfare";
import topNav from "../components/topNav";
import Bottom from "../components/Bottom";
export default {
  data() {
    return {
      // *全部列表
      alllist,
      list,
    };
  },
  components: {
    topNav,
    Bottom,
  },
  // 刷新一次
  mounted: function() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  methods: {
    //*切换激活状态
    toggle(i) {
      if (i + 1 == this.list.infoNum) {
        return;
      }
      this.list.infoNum = i + 1;
    },
  },
};
</script>

<style lang="less" scoped></style>
